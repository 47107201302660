<template>
    <v-main style="min-height: 80% !important">
        <v-container class="pb-12">
            <v-row>
                <v-col cols="12" class="py-0">
                    <h2
                        :style="{ color: parameters.primaryColor }"
                        class="d-flex flex-column text-left mb-3"
                        :class="$mq != 'lg' ? 'mt-3' : 'mt-6'"
                    >
                        Confirmação de clientes
                        <v-icon class="pr-2 align-self-end" @click="refresh" :color="parameters.secondaryColor">mdi-refresh</v-icon>
                    </h2>
                </v-col>
                <v-col cols="12" class="pt-0">
                    <CustomerList/>
                    <CustomerDetails/>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>
const CustomerList = () =>
    import(
        /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "@/views/manager/customer/CustomerList.vue"
    );
const CustomerDetails = () =>
    import(
        /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "@/views/manager/customer/CustomerDetails.vue"
    );
export default {
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
    },
    components:{
        CustomerList,
        CustomerDetails
    },
    methods:{
        refresh(){
            this.$store.dispatch("confirmCustomers/find")
        }
    }
};
</script>

<style>
</style>